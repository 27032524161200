<template src="./cms.html"></template>

<script>
import Http from '@/shared/http-config'
import CmsStore from './CmsStore'
import RegisterStoreModule from '@/shared/registerStoreModule'
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'Cms',
  mixins: [RegisterStoreModule],
  created: function(){
    this.registerStoreModule('cms', CmsStore);
  },
  computed: {
    ...mapFields('cms', [
      'current_nav',
    ]),
  },
  methods: {
    previewPage(){
      let data = {};
      let self = this;
      data.content = self.current_nav.content;
      Http.request('POST', '/website/navigation/' + self.current_nav.id + '/preview', data).then(function (response) {
        window.open(response.data + '?template=' + self.$route.params.template);
      });
    },
  }
}
</script>
